import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  public dashboardSubject: BehaviorSubject<any>;
  public dashboard: Observable<any>;

  constructor(private router: Router) {
    this.dashboardSubject = new BehaviorSubject<any>(
      localStorage.getItem('dashboardPage')
    );
    this.dashboard = this.dashboardSubject.asObservable();
  }

  public setDashboard(status: any) {
    localStorage.setItem('dashboardPage', status);
    this.dashboardSubject.next(status);
  }

  get currentDashboard(): any {
    return this.dashboardSubject.value;
  }
}
