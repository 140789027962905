import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { EnvService } from './services/env/env.service';
import { HttpClient } from '@angular/common/http';
import { AlertService } from './services/alert/alert.service';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { PushNotifService } from './services/push-notif/push-notif.service';
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp } from '@capacitor/app';
import { AuthService } from './services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    public router: Router,
    private appVersion: AppVersion,
    private env: EnvService,
    private http: HttpClient,
    private pushNotifService: PushNotifService,
    public alertService: AlertService,
    public authService: AuthService
  ) {
    this.initializeApp();
  }

  async initializeApp() {

    // if (Capacitor.isNativePlatform()) {
    //   CapacitorApp.getInfo().then(info => {
    //     console.log('info ' + info.version);
    //     this.getApiUrl(info.version);
    //   });
    // }else{
    //   this.env.setDemo();
    // }

    this.checkDemo();
      // this.statusBar.backgroundColorByHexString('#f04141');
      
      
      if (Capacitor.isNativePlatform()) {
        if (this.authService.currentTokenValue != null) {
          console.log('token auth : '+ this.authService.currentTokenValue);
          this.pushNotifService.initPushNotif();
        }
      }

    // this.platform.ready().then(() => {
    //   this.checkDemo();
    //   // this.statusBar.backgroundColorByHexString('#f04141');
      
    //   console.log('token auth : '+ this.authService.currentTokenValue);
      
    //   if (Capacitor.isNativePlatform()) {
    //     if (this.authService.currentTokenValue != null) {
    //       this.pushNotifService.initPushNotif();
    //     }
    //   }

    // });
  }

  async getApiUrl(version_number){
    const platform = await Capacitor.getPlatform();
    // console.log('platform ' + platform);
    this.http.get(this.env.demo_public_url + 'demo?type=1').subscribe((arg) => {      

      // console.log('demo ' + arg['data']['v']);
      
      if(arg && arg['success']){
        let data = arg['data'];

        // console.log('data vers ' + data.isDemoAndroid);
        
        if(version_number === data.v){
          if((platform === 'ios' && data.isDemoIOS == 1) || (platform === 'android' && data.isDemoAndroid == 1)){
            this.env.setDemo();
            this.alertService.showToast('Demo version ' + version_number, 'top');
          } else {
            this.env.setProd();
          }
        } else {
          this.env.setProd();
        }
      } else {
        this.env.setProd();
      }
    });
  }

  checkDemo() {
    var appVers = '1.1.2';
    this.appVersion.getVersionNumber().then(
      (value) => {
        if (this.platform.is('ios')) {
          this.http
            .get(
              this.env.public + 'demo/status?versi=' + value + '&platform=ios'
            )
            .subscribe(
              (arg) => {
                if (arg['demo']) {
                  localStorage.setItem('url', this.env.demo_url);
                  this.env.currentUrlSubject = new BehaviorSubject<any>(
                    localStorage.getItem('url')
                  );
                  localStorage.setItem('path', this.env.demo_path);
                  this.env.currentPathSubject = new BehaviorSubject<any>(
                    localStorage.getItem('path')
                  );
                  localStorage.setItem('url_public', this.env.demo_public_url);
                  this.env.currentUrlPublicSubject = new BehaviorSubject<any>(
                    localStorage.getItem('url_public')
                  );
                  this.alertService.showToast('Demo version ' + value, 'top');
                } else {
                  localStorage.setItem('url', this.env.prod_url);
                  this.env.currentUrlSubject = new BehaviorSubject<any>(
                    localStorage.getItem('url')
                  );
                  localStorage.setItem('path', this.env.prod_path);
                  this.env.currentPathSubject = new BehaviorSubject<any>(
                    localStorage.getItem('path')
                  );
                  localStorage.setItem('url_public', this.env.prod_public_url);
                  this.env.currentUrlPublicSubject = new BehaviorSubject<any>(
                    localStorage.getItem('url_public')
                  );
                }
              },
              (err) => {
                localStorage.setItem('url', this.env.prod_url);
                this.env.currentUrlSubject = new BehaviorSubject<any>(
                  localStorage.getItem('url')
                );
                localStorage.setItem('path', this.env.prod_path);
                this.env.currentPathSubject = new BehaviorSubject<any>(
                  localStorage.getItem('path')
                );
                localStorage.setItem('url_public', this.env.prod_public_url);
                this.env.currentUrlPublicSubject = new BehaviorSubject<any>(
                  localStorage.getItem('url_public')
                );
              }
            );
        } else {
          this.http
            .get(
              this.env.public +
                'demo/status?versi=' +
                value +
                '&platform=android'
            )
            .subscribe(
              (arg) => {
                if (arg['demo']) {
                  localStorage.setItem('url', this.env.demo_url);
                  this.env.currentUrlSubject = new BehaviorSubject<any>(
                    localStorage.getItem('url')
                  );
                  localStorage.setItem('path', this.env.demo_path);
                  this.env.currentPathSubject = new BehaviorSubject<any>(
                    localStorage.getItem('path')
                  );
                  localStorage.setItem('url_public', this.env.demo_public_url);
                  this.env.currentUrlPublicSubject = new BehaviorSubject<any>(
                    localStorage.getItem('url_public')
                  );
                  this.alertService.showToast('Demo version ' + value, 'top');
                } else {
                  localStorage.setItem('url', this.env.prod_url);
                  this.env.currentUrlSubject = new BehaviorSubject<any>(
                    localStorage.getItem('url')
                  );
                  localStorage.setItem('path', this.env.prod_path);
                  this.env.currentPathSubject = new BehaviorSubject<any>(
                    localStorage.getItem('path')
                  );
                  localStorage.setItem('url_public', this.env.prod_public_url);
                  this.env.currentUrlPublicSubject = new BehaviorSubject<any>(
                    localStorage.getItem('url_public')
                  );
                }
              },
              (err) => {
                localStorage.setItem('url', this.env.prod_url);
                this.env.currentUrlSubject = new BehaviorSubject<any>(
                  localStorage.getItem('url')
                );
                localStorage.setItem('path', this.env.prod_path);
                this.env.currentPathSubject = new BehaviorSubject<any>(
                  localStorage.getItem('path')
                );
                localStorage.setItem('url_public', this.env.prod_public_url);
                this.env.currentUrlPublicSubject = new BehaviorSubject<any>(
                  localStorage.getItem('url_public')
                );
              }
            );
        }
      },
      (err) => {
        localStorage.setItem('url', this.env.demo_url);
        this.env.currentUrlSubject = new BehaviorSubject<any>(
          localStorage.getItem('url')
        );
        localStorage.setItem('path', this.env.demo_path);
        this.env.currentPathSubject = new BehaviorSubject<any>(
          localStorage.getItem('path')
        );
        localStorage.setItem('url_public', this.env.demo_public_url);
        this.env.currentUrlPublicSubject = new BehaviorSubject<any>(
          localStorage.getItem('url_public')
        );
        // localStorage.setItem('url', this.env.prod_url);
        // this.env.currentUrlSubject = new BehaviorSubject<any>(
        //   localStorage.getItem('url')
        // );
        // localStorage.setItem('path', this.env.prod_path);
        // this.env.currentPathSubject = new BehaviorSubject<any>(
        //   localStorage.getItem('path')
        // );
      }
    );
  }
}
