import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';

export enum ConnectionStatus {
  Online,
  Offline,
}

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(
    ConnectionStatus.Offline
  );

  constructor(
    private network: Network,
    private toastController: ToastController,
    private platform: Platform
  ) {
    this.platform.ready().then(() => {
      this.initNetworkEvents();
      let status =
        this.network.type !== 'none'
          ? ConnectionStatus.Online
          : ConnectionStatus.Offline;
      this.status.next(status);
    });
  }

  public initNetworkEvents() {
    this.network.onDisconnect().subscribe(() => {
      console.log('disconnected');
      if (this.status.getValue() === ConnectionStatus.Online) {
        console.log('Network Disconnected ');
        this.updateNetworkStatus(ConnectionStatus.Offline);
      }
    });

    this.network.onConnect().subscribe(() => {
      console.log('connected');
      if (this.status.getValue() === ConnectionStatus.Offline) {
        console.log('Network Connected');
        this.updateNetworkStatus(ConnectionStatus.Online);
      }
    });
  }

  private async updateNetworkStatus(status: ConnectionStatus) {
    this.status.next(status);

    let connection = status == ConnectionStatus.Offline ? 'Offline' : 'Online';

    if (connection == 'Offline') {
      let toast = this.toastController.create({
        message: `Ups, koneksi internet anda terputus`,
        duration: 3000,
        position: 'bottom',
        cssClass: 'network-toast',
      });
      toast.then((toast) => toast.present());
    } else {
      let toast = this.toastController.create({
        message: `Koneksi internetmu sudah tersambung kembali`,
        duration: 3000,
        position: 'bottom',
        cssClass: 'network-toast success',
      });
      toast.then((toast) => toast.present());
    }
  }

  public onNetworkChange(): Observable<ConnectionStatus> {
    return this.status.asObservable();
  }

  public get currentNetworkStatus(): ConnectionStatus {
    return this.status.getValue();
  }
}
