import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EnvService {
  currentUrlSubject: BehaviorSubject<any>;
  currentPathSubject: BehaviorSubject<any>;
  currentUrlPublicSubject: BehaviorSubject<any>;

  public = 'https://sidumas.badungkab.go.id/api/';
  prod_url = 'https://sidumas.badungkab.go.id/api/admin/';
  prod_path = 'https://sidumas.badungkab.go.id/';
  demo_url = 'https://demo-sidumas.badungkab.go.id/api/admin/';
  demo_path = 'https://demo-sidumas.badungkab.go.id/';

  prod_public_url = 'https://sidumas.badungkab.go.id/api/';
  demo_public_url = 'https://demo-sidumas.badungkab.go.id/api/';

  // public = 'http://127.0.0.1:8000/api/';
  // prod_url = 'http://127.0.0.1:8000/api/admin/'; prod_path = 'http://127.0.0.1:8000/';
  // demo_url = 'http://127.0.0.1:8000/api/admin/'; demo_path = 'http://127.0.0.1:8000/';

  constructor() {
    this.currentUrlSubject = new BehaviorSubject<any>(
      localStorage.getItem('url')
    );
    this.currentPathSubject = new BehaviorSubject<any>(
      localStorage.getItem('path')
    );
    this.currentUrlPublicSubject = new BehaviorSubject<any>(
      localStorage.getItem('url_public')
    );
  }

  get url(): any {
    return this.currentUrlSubject.value;
  }

  get path(): any {
    return this.currentPathSubject.value;
  }
  
  get url_public(): any {
    return this.currentUrlPublicSubject.value;
  }

  setProd() {
    localStorage.setItem('url', this.prod_url);
    this.currentUrlSubject = new BehaviorSubject<any>(
      localStorage.getItem('url')
    );
    localStorage.setItem('path', this.prod_path);
    this.currentPathSubject = new BehaviorSubject<any>(
      localStorage.getItem('path')
    );
    localStorage.setItem('url_public', this.prod_public_url);
    this.currentUrlPublicSubject = new BehaviorSubject<any>(
      localStorage.getItem('url_public')
    );
  }

  setDemo() {
    localStorage.setItem('url', this.demo_url);
    this.currentUrlSubject = new BehaviorSubject<any>(
      localStorage.getItem('url')
    );
    localStorage.setItem('path', this.demo_path);
    this.currentPathSubject = new BehaviorSubject<any>(
      localStorage.getItem('path')
    );
    localStorage.setItem('url_public', this.demo_public_url);
    this.currentUrlPublicSubject = new BehaviorSubject<any>(
      localStorage.getItem('url_public')
    );
  }

}
