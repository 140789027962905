import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { EnvService } from '../services/env/env.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private env: EnvService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentToken = this.authService.currentTokenValue;
    let headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    if (currentToken && request.url.startsWith(this.env.url) || currentToken && request.url.startsWith(this.env.url_public)) {
      headers['Authorization'] = `Bearer ${currentToken}`;
    }

    request = request.clone({
      setHeaders: headers,
    });
    return next.handle(request);
  }
}
