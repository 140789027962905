import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  loading_option = {
    spinner : null,
    backdropDismiss : false,
    message : '<div class="cssload-loader">SIDUMAS</div>',
    cssClass: 'sidumas-loading',
    keyboardClose: true,
    animated: true,
  }

  constructor() { }
}
