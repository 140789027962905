import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { Chart } from 'chart.js';
import { AlertService } from 'src/app/services/alert/alert.service';
import { DataService } from 'src/app/services/data/data.service';

@Component({
  selector: 'app-modal-statistik',
  templateUrl: './modal-statistik.component.html',
  styleUrls: ['./modal-statistik.component.scss'],
})
export class ModalStatistikComponent implements OnInit {
  @ViewChild('pieChart', { static: false }) pieChart;
  @ViewChild(IonContent, { static: false }) ionContent: IonContent;

  id: any;
  title: any;
  pie: any;
  dataKec: any = [];
  status: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    public dataService: DataService,
    public alertService: AlertService
  ) {}

  ngOnInit() {
    this.getKecamatanPie();
  }

  ionViewWillEnter() {
    this.getKecamatanPie();
    this.getDetailStatistik();
  }

  createPieChartKecamatan(data, label) {
    this.pie = new Chart(this.pieChart.nativeElement, {
      type: 'pie',
      data: {
        datasets: [
          {
            label: 'Pengaduan Berdasarkan Jenis Kategori',
            data: data,
            backgroundColor: [
              '#3399FF',
              '#FF3333',
              '#FF9933',
              '#FFFF33',
              '#99FF33',
              '#FF33FF',
              '#33FF99',
              '#33FFFF',
              '#9933FF',
              '#FF3399',
            ],
          },
        ],

        labels: label,
      },
    });
  }

  getKecamatanPie() {
    let labels: any[] = [];
    let data_count: any[] = [];
    this.dataService.getPieKecamatan(this.id).subscribe((data) => {
      if (data['data'].length === 0) {
        this.status = false;
      } else {
        this.status = true;
        data['data'].forEach((e) => {
          labels.push(e['kategori_name']);
          data_count.push(e['jml_pengaduan']);
        });
      }

      this.createPieChartKecamatan(data_count, labels);
    });
  }

  getDetailStatistik() {
    this.dataService.getStatistikKecamatan(this.id).subscribe(
      (data) => {
        this.dataKec = data['data'];
        console.log(this.dataKec);
      },
      (err) => {
        this.alertService.alertError('Tidak dapat memuat permintaan');
      }
    );
  }

  cancel() {
    return this.modalCtrl.dismiss();
  }
}
