import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '../env/env.service';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  constructor(private http: HttpClient, private env: EnvService) {}

  requestGet(url, params) {
    return this.http.get(this.env.url + url, params).pipe();
  }

  requestPost(url, body) {
    return this.http.post(this.env.url + url, body).pipe();
  }
  
  requestPostPublic(url, body) {
    return this.http.post(this.env.demo_public_url + url, body).pipe();
  }

  requestDelete(url, body) {
    return this.http.delete(this.env.url + url, body).pipe();
  }

  requestPatch(url, body) {
    return this.http.patch(this.env.url + url, body).pipe();
  }
}
