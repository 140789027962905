import { PushNotifications, ActionPerformed,
} from '@capacitor/push-notifications';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { RequestService } from '../request/request.service';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../env/env.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PushNotifService {
  constructor(
    private router: Router,
    private req:RequestService,
    public http: HttpClient,
    public env: EnvService
  ) {}

  initPushNotif() {
    this.checkPermission();
    this.registerPushNotif();
  }

  async checkPermission() {
    let permstat = await PushNotifications.checkPermissions();

    if (permstat.receive === 'prompt') {
      await PushNotifications.requestPermissions();
    }

    if (permstat.receive !== 'granted') {
      console.error('Permission Denied !');
    }

    await PushNotifications.register();
  }

  async registerPushNotif() {
    PushNotifications.addListener('registration', (token) => {
      let formData = {
        fcm_token: token.value,
      };

      // this.req.requestPostPublic('fcm-token', formData).subscribe(
      //   (ress) => {
      //     console.log('fcm : '+ress);
      //   }
      // );

    this.http
      .post(this.env.url_public + 'fcm-token', formData).subscribe(
        (res) => {
          console.log('fcm '+ res);
        }
      );
      
      console.info('Registration token: ', token.value);
    });

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        console.log('notif redirect => ' + JSON.stringify(notification.data.redirect));

        if (
          typeof notification.title != 'undefined' &&
          typeof notification.body != 'undefined'
        ) {

          if (Capacitor.getPlatform() === 'android') {
            if (
              typeof notification.data.notification_toaster == 'undefined' ||
              (typeof notification.data.notification_toaster != 'undefined' &&
                notification.data.notification_toaster != '0')
            ) {}
          }
        }

        console.log('Push notification received: ', notification);
      }
    );

    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification) => {

        console.log('when clic redirect : ' + JSON.stringify(notification.notification.data.redirect));
        
        // this.router.navigate([`app/tabs/home`]);
        this.router.navigate([notification.notification.data.redirect]);
        console.log(
          'Push notification action performed',
          notification.actionId,
          notification.inputValue
        );
      }
    );
  }

  resetBadgeCount() {
    PushNotifications.removeAllDeliveredNotifications();
  }
}
