import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'app/tabs/home',
    pathMatch: 'full',
  },
  {
    path: 'app',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/auth/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/auth/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./pages/about/about.module').then((m) => m.AboutPageModule),
  },
  {
    path: 'detail-pengaduan/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/detail-pengaduan/detail-pengaduan.module').then(
        (m) => m.DetailPengaduanPageModule
      ),
  },
  {
    path: 'detail-verify/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/detail-verify/detail-verify.module').then(
        (m) => m.DetailVerifyPageModule
      ),
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/notifications/notifications.module').then(
        (m) => m.NotificationsPageModule
      ),
  },
  {
    path: 'pengaduan',
    loadChildren: () =>
      import('./pages/pengaduan/pengaduan.module').then(
        (m) => m.PengaduanPageModule
      ),
  },
  {
    path: 'pengaduan-verifback/:id',
    loadChildren: () =>
      import('./pages/pengaduan-verifback/pengaduan-verifback.module').then(
        (m) => m.PengaduanVerifbackPageModule
      ),
  },
  {
    path: 'pengaduan-verify/:id',
    loadChildren: () =>
      import('./pages/pengaduan-verify/pengaduan-verify.module').then(
        (m) => m.PengaduanVerifyPageModule
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'profile-edit',
    loadChildren: () =>
      import('./pages/profile-edit/profile-edit.module').then(
        (m) => m.ProfileEditPageModule
      ),
  },
  {
    path: 'profile-password',
    loadChildren: () =>
      import('./pages/profile-password/profile-password.module').then(
        (m) => m.ProfilePasswordPageModule
      ),
  },
  {
    path: 'project',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/project/project.module').then((m) => m.ProjectPageModule),
  },
  {
    path: 'verify',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/verify/verify.module').then((m) => m.VerifyPageModule),
  },
  {
    path: 'pengaduan-process/:id',
    loadChildren: () =>
      import('./pages/pengaduan-process/pengaduan-process.module').then(
        (m) => m.PengaduanProcessPageModule
      ),
  },
  {
    path: 'show-map',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/show-map/show-map.module').then(
        (m) => m.ShowMapPageModule
      ),
  },
  {
    path: 'pengaduan-baru',
    loadChildren: () => import('./pages/pengaduan-baru/pengaduan-baru.module').then( m => m.PengaduanBaruPageModule)
  },
  {
    path: 'bwise',
    loadChildren: () => import('./pages/bwise/bwise.module').then( m => m.BwisePageModule)
  },
  {
    path: 'detail-pengaduan-bwise/:id',
    loadChildren: () => import('./pages/detail-pengaduan-bwise/detail-pengaduan-bwise.module').then( m => m.DetailPengaduanBwisePageModule)
  },
  {
    path: 'pengaduan-verify-bwise/:id',
    loadChildren: () => import('./pages/pengaduan-verify-bwise/pengaduan-verify-bwise.module').then( m => m.PengaduanVerifyBwisePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: false }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
