import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../env/env.service';
import { tap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { DashboardService } from '../dashboard/dashboard.service';
import { AlertService } from '../alert/alert.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentTokenSubject: BehaviorSubject<string>;
  public currentToken: Observable<string>;
  public fcmToken: any;
  public currentUserSubject: BehaviorSubject<any>;

  // roleBwise: BehaviorSubject<any>;

  bwiseSubject: BehaviorSubject<any>;
  bwise: Observable<any>;

  constructor(
    private http: HttpClient,
    private env: EnvService,
    public navCtrl: NavController,
    private dashboardService: DashboardService,
    public alertService: AlertService,
  ) {
    this.currentTokenSubject = new BehaviorSubject<string>(
      localStorage.getItem('currentToken')
    );
    this.currentToken = this.currentTokenSubject.asObservable();
    this.currentUserSubject = new BehaviorSubject<string>(
      JSON.parse(localStorage.getItem('user'))
    );

    // this.roleBwise = new BehaviorSubject<any>(localStorage.getItem('role_bwise'));

    this.bwiseSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('role_bwise'))
    );
    this.bwise = this.bwiseSubject.asObservable();

  }

  login(username: any, password: any) {
    let formData = {
      username: username,
      password: password
    };
    return this.http
      .post(this.env.url + 'login', formData)
      .pipe(
        tap(
          (data) => {            
            localStorage.setItem('currentToken', data['token']);
            this.currentTokenSubject.next(data['token']);
            this.dashboardService.setDashboard(false);

            return data;
          },
          (err) => {
            console.log(err);
          }
        )
      );
  }

  get currentTokenValue(): any {
    return this.currentTokenSubject.value;
  }

  get currentUserValue(): any {
    return this.currentUserSubject.value;
  }


  public get bwiseRole(): boolean {
    return this.bwiseSubject.value;
  }

  storeBwise(data: any) {
    localStorage.setItem('role_bwise', JSON.stringify(data));
    this.bwiseSubject.next(data);
  }

  getUser() {
    return this.http.get(this.env.url + 'user').pipe();
  }

  logout() {
    localStorage.removeItem('currentToken');
    this.currentTokenSubject.next(null);
    localStorage.removeItem('user');
    this.currentUserSubject.next(null);
    this.dashboardService.setDashboard(false);
    this.navCtrl.navigateRoot('/login');
    localStorage.removeItem('role_bwise');
    this.bwiseSubject.next('');
  }
}
