import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { Loader } from '../services/loader/loader.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private loader: Loader) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        console.log(err);
        if ([401, 403].indexOf(err.status) !== -1) {
          // 401 Unauthorized / 403 Forbidden
          this.authService.logout();
        }

        this.loader.dismiss();
        const error = err.error;
        return throwError(error);
      })
    );
  }
}
