import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { EnvService } from '../env/env.service';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(
    public http: HttpClient,
    public env: EnvService,
    public authService: AuthService
  ) {}

  //dashboard-home
  getDashboardStat(year) {
    return this.http
      .get(this.env.url + 'dashboard/home/stat?tahun=' + year)
      .pipe();
  }

  //akun
  getPelapor(page: any = '', filter: number = null, search: any = '') {
    return this.http
      .get(
        this.env.url +
          'akun?filter=' +
          filter +
          '&search=' +
          search +
          '&page=' +
          page
      )
      .pipe();
  }

  getPelaporStat() {
    return this.http.get(this.env.url + 'akun/stat').pipe();
  }

  getDetailPelapor(id) {
    return this.http.get(this.env.url + 'akun/' + id).pipe();
  }

  /**
   * verifikasi akun
   */
  verifyAkunCheckAccess() {
    return this.http.get(this.env.url + 'akun/verif-access').pipe();
  }
  verifyAkun(id, data) {
    return this.http.post(this.env.url + 'akun/' + id + '/verify', data).pipe();
  }
  cancelVerifiyAkun(id) {
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.authService.currentTokenValue,
    });
    return this.http
      .post(this.env.url + 'akun/' + id + '/cancel', { headers: headers })
      .pipe();
  }

  //pengaduan
  getAllAduans(
    page: any = '',
    search: any = '',
    category: any = '',
    statusByRole: string = '',
    byopd: boolean = false
  ) {
    let filterByOpd = byopd ? 0 : 1;
    return this.http
      .get(
        this.env.url +
          'pengaduan?page=' +
          page +
          '&search=' +
          search +
          '&category=' +
          category +
          '&statusByRole=' +
          statusByRole +
          '&byopd=' +
          filterByOpd
      )
      .pipe();
  }

  // pengaduan bwise
  getAllAduanBwise(
    page: any = '',
    search: any = '',
    category: any = '',
    statusByRole: string = '',
    byopd: boolean = false
  ) {
    let filterByOpd = byopd ? 0 : 1;
    return this.http
      .get(
        this.env.url +
          'pengaduan-bwise?page=' +
          page +
          '&search=' +
          search +
          '&category=' +
          category +
          '&statusByRole=' +
          statusByRole +
          '&byopd=' +
          filterByOpd
      )
      .pipe();
  }

  // pengaduan terbaru
  getAllAduansTerbaru(page: any = '', search: any = '') {
    // let filterByOpd = byopd ? 0 : 1;
    return this.http
      .get(
        this.env.url + 'pengaduan-terbaru?page=' + page + '&search=' + search
      )
      .pipe();
  }

  getPengaduanStat() {
    return this.http.get(this.env.url + 'pengaduan/stat').pipe();
  }

  getPengaduanStat2(byopd: boolean) {
    console.log('dari dataService.getPengaduanStat2: ' + byopd);
    return this.http.get(this.env.url + 'pengaduan/stat2').pipe();
  }

  getPengaduanStatBwise(byopd: boolean) {
    console.log('dari dataService.bwise: ' + byopd);
    return this.http.get(this.env.url + 'pengaduan/stat2-bwise').pipe();
  }

  getDetailPengaduan(id) {
    return this.http.get(this.env.url + 'pengaduan/' + id).pipe();
  }

  getDetailPengaduanBwise(id) {
    return this.http.get(this.env.url + 'pengaduan-bwise/' + id).pipe();
  }

  getStatusPengaduanBwise(id) {
    return this.http.get(this.env.url + 'pengaduan-bwise/status/' + id).pipe();
  }

  verifyPengaduan(id: number, form: FormGroup) {
    let data = JSON.stringify({
      kategori_id: form.value.kategori,
      subkategori_id: form.value.subkategori,
      opd_id: form.value.opds,
    });
    return this.http
      .post(this.env.url + 'pengaduan/' + id + '/verify', data)
      .pipe();
  }

  verifBackPengaduan(id: number, form: FormGroup) {
    let data = JSON.stringify({
      tanggapan: form.value.tanggapan,
    });
    return this.http
      .post(this.env.url + 'pengaduan/' + id + '/backverif', data)
      .pipe();
  }

  closePengaduan(id) {
    return this.http.get(this.env.url + 'pengaduan/' + id + '/close').pipe();
  }

  prosesPengaduan(id: number, pictures: [], form: FormGroup) {
    let data = JSON.stringify({
      pictures: pictures,
      tanggapan: form.value.tanggapan,
    });

    return this.http
      .post(this.env.url + 'pengaduan/' + id + '/proses', data)
      .pipe();
  }

  backPelaporPengaduan(id: number) {
    return this.http
      .get(this.env.url + 'pengaduan/' + id + '/backpelapor')
      .pipe();
  }

  backLuarPemkabPengaduan(id: number) {
    return this.http
      .get(this.env.url + 'pengaduan/' + id + '/backluarpemkab')
      .pipe();
  }
  
  persetujuanSekretarisPengaduan(id: number) {
    return this.http
      .get(this.env.url + 'pengaduan/' + id + '/persetujuan-sekretaris')
      .pipe();
  }
  
  persetujuanPimpinanPengaduan(id: number) {
    return this.http
      .get(this.env.url + 'pengaduan/' + id + '/persetujuan-pimpinan')
      .pipe();
  }

  getVerifikasiKategori() {
    return this.http.get(this.env.url + 'verifikasi/kategori').pipe();
  }

  getVerifikasiKategoriSub(id_kategori: number) {
    return this.http
      .get(this.env.url + 'verifikasi/subkategori/' + id_kategori)
      .pipe();
  }

  getVerifikasiOpd(id_subkategori: number) {
    return this.http
      .get(this.env.url + 'verifikasi/opd/' + id_subkategori)
      .pipe();
  }

  getKomentar(id_pengaduan: number, take: number = 0) {
    return this.http
      .get(
        this.env.url +
          'pengaduan/' +
          id_pengaduan +
          '/komentar' +
          (take == 0 ? '' : '?l=' + take)
      )
      .pipe();
  }

  getTanggapan(id_pengaduan: number, take: number = 0) {
    return this.http
      .get(
        this.env.url +
          'pengaduan/' +
          id_pengaduan +
          '/tanggapan' +
          (take == 0 ? '' : '?l=' + take)
      )
      .pipe();
  }

  getLikes(id_pengaduan: number) {
    return this.http
      .get(this.env.url + 'pengaduan/' + id_pengaduan + '/likes')
      .pipe();
  }

  addKomentar(id_pengaduan: number, form: FormGroup) {
    let data = JSON.stringify({
      komentar: form.value.komentar,
    });
    return this.http
      .post(this.env.url + 'pengaduan/' + id_pengaduan + '/komentar', data)
      .pipe();
  }

  addTanggapan(id_pengaduan: number, form: FormGroup) {
    let data = JSON.stringify({
      tanggapan: form.value.tanggapan,
    });
    return this.http
      .post(this.env.url + 'pengaduan/' + id_pengaduan + '/tanggapan', data)
      .pipe();
  }

  getStatusPrivilege(id_pengaduan: number, id_status: number) {
    return this.http
      .get(
        this.env.url +
          'priv/status/' +
          id_status +
          '?id_pengaduan=' +
          id_pengaduan
      )
      .pipe();
  }
  
  getStatusPrivilegeBwise(id_pengaduan: number, id_status: number) {
    console.log('pengaduan : '+id_pengaduan);
    console.log('status : '+id_status);
    
    return this.http
      .get(
        this.env.url +
          'priv/status-bwise/' +
          id_status +
          '?id_pengaduan=' +
          id_pengaduan
      )
      .pipe();
  }

  checkRoleBwise() {
    return this.http
      .get(
        this.env.url +
          'check-role-bwise'
      )
      .pipe();
  }

  getAbout(id: number = null) {
    let u = 'app/about';
    if (id != null) {
      u = 'app/about/' + id;
    }

    return this.http.get(this.env.url + u).pipe();
  }

  getNotifByRole(page: number) {
    return this.http.get(this.env.url + 'notifikasi?page=' + page).pipe();
  }

  readNotif(id_notif: number) {
    return this.http
      .get(this.env.url + 'notifikasi/' + id_notif + '/read')
      .pipe();
  }

  readAllNotif() {
    return this.http.get(this.env.url + 'notifikasi/read-all').pipe();
  }

  countNotif() {
    return this.http.get(this.env.url + 'notifikasi/count').pipe();
  }

  // map polygon
  getMapPolygon() {
    return this.http.get(this.env.url + 'map/badungpolygon/get').pipe();
  }

  getMapPolygonDetail(polygon_id) {
    return this.http.get(
      this.env.url + 'map/polygon/verydetail/' + polygon_id,
      {
        responseType: 'text',
      }
    );
  }

  //dashboard-home-full
  getDashboardStatFull(year) {
    return this.http
      .get(this.env.url + 'dashboard/home/statfull?tahun=' + year)
      .pipe();
  }

  getPieKecamatan(polygon_id) {
    return this.http.get(this.env.url + 'map/polygon/pie/' + polygon_id).pipe();
  }

  getStatistikKecamatan(polygon_id) {
    return this.http
      .get(this.env.url + 'map/polygon/statistik/' + polygon_id)
      .pipe();
  }
}
