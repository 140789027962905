import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInterceptor } from './interceptors/header.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnlargeImageComponent } from './components/enlarge-image/enlarge-image.component';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { NetworkService } from './services/network/network.service';
import { ModalStatistikComponent } from './pages/modal-statistik/modal-statistik.component';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
@NgModule({
  declarations: [AppComponent, EnlargeImageComponent, ModalStatistikComponent],
  entryComponents: [EnlargeImageComponent, ModalStatistikComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [
    AppVersion,
    Camera,
    NativeGeocoder,
    Network,
    NetworkService,
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
