import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GestureController, NavParams, PopoverController } from '@ionic/angular';
import { EnvService } from 'src/app/services/env/env.service';

@Component({
  selector: 'app-enlarge-image',
  templateUrl: './enlarge-image.component.html',
  styleUrls: ['./enlarge-image.component.scss'],
})
export class EnlargeImageComponent implements OnInit {
  @ViewChild('img', { static: false }) imgElem: ElementRef;

  slideOpts: any = {
    initialSlide: 1,
    speed: 400,
    zoom: {
      maxRatio: 5,
    },
  };
  passed_id: any;
  passed_files: any;
  gesture: GestureController;

  constructor(
    private navParams: NavParams,
    public envService: EnvService,
    public popoverCtrl: PopoverController
  ) {}

  ngOnInit() {
    this.passed_files = this.navParams.get('files');
  }
}
