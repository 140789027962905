import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { SharedService } from '../shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class Loader {

  isLoading : boolean = false;

  constructor(private loadingCtrl : LoadingController, 
              private sharedService : SharedService) {
    
   }

   async present(){
     this.isLoading = true;
     return await this.loadingCtrl.create(this.sharedService.loading_option)
     .then( a => {
       a.present().then(() => {
         if(!this.isLoading) {
           a.dismiss().then(() => console.log('abort presenting'));
         }
       })
     })
   }

   async dismiss() {
     this.isLoading = false;
     return await this.loadingCtrl.dismiss().then(() => console.log('dismissed!'));
   }
}
