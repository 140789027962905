import { Injectable } from '@angular/core';
import {
  AlertController,
  ModalController,
  ToastController,
} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    public alertCtrl: AlertController,
    public toastCtrl: ToastController,
    public modalCtrl: ModalController
  ) {}

  async alertSuccess(message) {
    const alert = await this.alertCtrl.create({
      header: 'Success',
      message: message,
      animated: true,
      cssClass: 'success-alert',
      buttons: ['Ok'],
    });

    await alert.present();
  }

  async alertError(message) {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message: message,
      animated: true,
      cssClass: 'error-alert',
      buttons: ['Ok'],
    });

    await alert.present();
  }

  async alertWarning(message) {
    const alert = await this.alertCtrl.create({
      header: 'Warning',
      message: message,
      animated: true,
      cssClass: 'warning-alert',
      buttons: ['Ok'],
    });

    await alert.present();
  }

  async alertInfo(message) {
    const alert = await this.alertCtrl.create({
      header: 'Info',
      message: message,
      animated: true,
      cssClass: 'info-alert',
      buttons: ['Ok'],
    });

    await alert.present();
  }

  async toastSuccess(message) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 3000,
      cssClass: 'toast-success',
    });

    await toast.present();
  }

  async toastWarning(message) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 3000,
      cssClass: 'toast-warning',
    });

    await toast.present();
  }

  async toastInfo(message) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 3000,
      cssClass: 'toast-info',
    });

    await toast.present();
  }

  async toastError(message) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 3000,
      cssClass: 'toast-error',
    });

    await toast.present();
  }

  async showToast(message, position) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 2000,
      position: position,
    });

    await toast.present();
  }

  async showAlert(header, message) {
    const alert = await this.alertCtrl.create({
      header: header,
      message: message,
      animated: true,
      cssClass: 'custom-alert',
      buttons: ['Ok'],
    });

    await alert.present();
  }
}
